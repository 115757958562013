

































import Vue, { PropType } from 'vue';
import BookingButtonsModal from '@/components/orderDetailParts/BookingButtonsModal.vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import AdminOrderService from '@/services/admin/AdminOrderService';
import ToastMessage from '@/models/ToastMessage';
import Notification from '@/components/shared/Notification.vue';

export default Vue.extend({
  components: {
    BookingButtonsModal,
    Notification,
  },
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
  data() {
    return {
      showCancelModal: false,
      showSuccessNotification: false,
      showSpinnnerInCancelButton: false,
    };
  },
  computed: {
    canBeCanceled(): boolean {
      if (this.order.status == 'Pending' || this.order.status == 'Confirmed') {
        return true;
      }
      return false;
    },

    getNotificationMessage(): string {
      return this.$t('components.AdminOrderDetailButtons.cancelNotification').toString();
    },
  },
  methods: {
    handleCancel() {
      this.showCancelModal = true;
    },

    confirmCancellation(show: boolean, message: string) {
      this.showCancelModal = show;
      AdminOrderService.cancelOrder(this.order.orderId, message).then(
        () => {
          this.showSpinnnerInCancelButton = false;
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('components.hostOrderDetailButtons.successMsg').toString(), 'bg-success')
          );
          this.showSuccessNotification = true;
          this.$emit('loadData');
        },
        (error) => {
          this.showSpinnnerInCancelButton = false;
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(
              this.$t('components.hostOrderDetailButtons.errorMsg').toString() + error.message,
              'bg-warning'
            )
          );
        }
      );
    },

    onCloseModal() {
      this.showCancelModal = false;
    },

    handleCloseSuccessNotification() {
      this.$router.push({ name: 'AdminOrderIndex' });
    },
  },
});
