










































































import Vue, { PropType, VueConstructor } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import FormaterMixin from '@/mixins/FormaterMixin.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
      required: true,
    },
  },
  computed: {
    canMessageToGuest(): boolean {
      return !this.order.guestIsDeleted;
    },
  },
  methods: {
    messageToGuest() {
      let routeData = this.$router.resolve({
        name: 'HostChatMessageDetail',
        params: { guestId: this.order.customerId },
      });
      window.open(routeData.href, '_blank');
    },
  },
});
