

















import Vue from 'vue';
import AdminOrderService from '@/services/admin/AdminOrderService';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';
import AdminOrderDetailGeneralInformationPart from '@/components/orderDetailParts/AdminOrderDetailGeneralInformationPart.vue';
import OrderDetailPricePart from '@/components/orderDetailParts/OrderDetailPricePart.vue';
import OrderDetailImportantInformationPart from '@/components/orderDetailParts/OrderDetailImportantInformationPart.vue';
import OrderDetailEventsPart from '@/components/orderDetailParts/OrderDetailEventsPart.vue';
import AdminOrderDetailButtons from '@/components/orderDetailParts/AdminOrderDetailButtons.vue';
import HostOrderDetailTitlePart from '@/components/orderDetailParts/HostOrderDetailTitlePart.vue';
import AdminOrderNotesPart from '@/components/orderDetailParts/AdminOrderNotesPart.vue';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  components: {
    AdminOrderDetailGeneralInformationPart,
    OrderDetailPricePart,
    OrderDetailImportantInformationPart,
    OrderDetailEventsPart,
    AdminOrderDetailButtons,
    HostOrderDetailTitlePart,
    AdminOrderNotesPart,
  },
  props: {
    orderId: {
      type: String,
    },
  },
  data() {
    return {
      order: new CustomerOrderDetailDto(),
      ready: false,
    };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostOrderDetail.meta.title', { playroomName: this.order.playroomName }).toString(),
    };
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { to: { name: 'AdminOrderIndex' }, translationKey: 'pages.host.HostOrderDetail.breadcrumb.orders' },
        { text: this.order.playroomName },
      ]);
    },

    loadData() {
      AdminOrderService.getDetail(this.orderId).then((res) => {
        this.order = res.value;
        this.ready = true;
        this.setBreadCrumb();
      });
    },

    formatCurrency(item: number) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item);
    },

    handleReloadData() {
      this.ready = false;
      this.loadData();
    },
  },
});
