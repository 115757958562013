












import Vue, { PropType, VueConstructor } from 'vue';
import CustomerOrderDetailDto from '@/models/CustomerOrderDetailDto';

export default (Vue as VueConstructor<Vue>).extend({
  props: {
    order: {
      type: Object as PropType<CustomerOrderDetailDto>,
    },
  },
});
